import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopBanner from 'src/components/Campaign/merchant/202404_WW/TopBanner';
import Campaign from 'src/components/Campaign/merchant/202404_WW/Campaign';
import Merits from 'src/components/Campaign/merchant/atobaraisodanshitsu/Merits';
import WhatIsPaidy from 'src/components/Campaign/merchant/atobaraisodanshitsu/WhatIsPaidy';
import NPay from 'src/components/Campaign/merchant/atobaraisodanshitsu/NPay';
import Application from 'src/components/Campaign/merchant/202403_plusshipping/Application';
import ImgApplication0 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-0.png';
import ImgApplication1 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-1.png';
import ImgApplication2 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-2.png';
import ImgApplication3 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-3.png';

const DATA = [
  {
    image: ImgApplication0,
    title: (
      <>
        ｢ペイディ決済らくらく <br /> 設定」経由での申込
      </>
    ),
    info: 'ワンクリックでお申込みが完了します。情報の入力は必要ありません。',
  },
  {
    image: ImgApplication1,
    title: '加盟店審査',
    info: '追加情報が必要な場合がございます。',
  },
  {
    image: ImgApplication2,
    title: '審査結果をメールでご連絡',
    info: '1週間以内（目安）にご連絡いたします。',
  },
  {
    image: ImgApplication3,
    title: 'ご利用スタート',
    info: '初期設定が完了したらご利用開始となります。',
  },
];

const SEOProps = {
  title: SITE_METADATA.campaign_202404_WW.title,
  description: SITE_METADATA.campaign_202404_WW.description,
};

export default function Campaign202404WW() {
  return (
    <Layout hasNoHeader hasNoFooter SEOProps={SEOProps}>
      <TopBanner />
      <Campaign />
      <Merits />
      <WhatIsPaidy />
      <NPay />
      <Application data={DATA} />
    </Layout>
  );
}
