import { Box, Container } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import ImgTopBanner from 'src/images/campaign/merchant/202403_plusshipping/rightTopBanner.png';
import ImgLogoHeader from 'src/images/logo-color-header.png';
import styles from './TopBanner.module.scss';

export default function TopBanner() {
  return (
    <>
      <section className={styles.section}>
        <Container>
          <Box display="flex" flexWrap="wrap" className={styles.row}>
            <Box className={styles.logoContainer}>
              <img alt="logo" src={ImgLogoHeader} width={142} height={56} />
              <p className={styles.subtitle}>
                加盟店手数料1か月無料！お試しキャンペーン実施中
              </p>
              <h1 className={cx(styles.h1, styles.show)}>
                手数料無料*！
                <br />
                分割あと払い決済「ペイディ」
              </h1>
              <h1 className={cx(styles.h1, styles.showMobile)}>
                手数料無料のデジタル
                <br />
                分割支払い「ペイディ」
              </h1>
              <div
                className={cx(styles.textWrapper, styles.textWrapperColMobile)}
              >
                <div className={styles.textColumnWrapper}>
                  <p className={cx(styles.text, styles.show)}>
                    クレジットカード不要、
                    <br />
                    事前登録不要で、 <br />
                    <span className={styles.textBold}>
                      カゴ落ちを防止
                    </span>して <br />
                    売上アップに貢献。
                  </p>
                  <p className={cx(styles.text, styles.showMobile)}>
                    クレジットカード不要、 事前登録不要で、 <br />
                    <span className={styles.textBold}>カゴ落ちを防止</span>して
                    売上アップに貢献。
                  </p>
                </div>
                <div className={styles.textColumnWrapper}>
                  <p className={cx(styles.text, styles.show)}>
                    分割手数料無料*の <br />
                    3・6・12回あと払いで、
                    <br />{' '}
                    <span className={styles.textBold}>
                      高額商品の売上アップ
                    </span>
                    <br />
                    が見込める。
                  </p>
                  <p className={cx(styles.text, styles.showMobile)}>
                    分割手数料無料*の3・6・12回あと払いで、
                    <br />
                    <span className={styles.textBold}>
                      高額商品の売上アップ
                    </span>
                    が見込める。
                  </p>
                  <p className={cx(styles.textSmall, styles.textSubHighlight)}>
                    *口座振替・銀行振込のみ無料
                  </p>
                </div>
              </div>
              <div
                className={cx(
                  styles.textWrapper,
                  styles.textWrapperHighlightsMobile
                )}
              >
                <div
                  className={cx(
                    styles.textColumnWrapper,
                    styles.textWrapperHighlight,
                    styles.textWrapperHighlightNo1
                  )}
                >
                  <p className={styles.textHighlight}>
                    業界<span className={styles.textHighlightNo1}>No.1</span>
                  </p>
                  <p className={cx(styles.textSmall, styles.textSubHighlight)}>
                    ※後払い決済導入社数に <br /> おいて。当社調べ。
                  </p>
                </div>
                <div
                  className={cx(
                    styles.textColumnWrapper,
                    styles.textWrapperHighlight,
                    styles.textWrapperHighlightShopify
                  )}
                >
                  <p className={cx(styles.textHighlight)}>業界最多!</p>
                  <p
                    className={cx(
                      styles.textHighlight,
                      styles.textHighlightShopify
                    )}
                  >
                    70万サイト以上
                  </p>
                  <p
                    className={cx(
                      styles.textHighlight,
                      styles.textHighlightShopify
                    )}
                  >
                    での利用実績
                  </p>
                  <p className={cx(styles.textSmall, styles.textSubHighlight)}>
                    ※2024年1月時点。
                  </p>
                </div>
              </div>
            </Box>
            <Box className={styles.topBannerWrapper}>
              <img
                alt="top-banner"
                src={ImgTopBanner}
                className={styles.banner}
              />
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
