/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Box, Container } from '@material-ui/core';
import styles from './Campaign.module.scss';

export default function Campaign() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.card}>
          <div>
            <p className={styles.head}>
              ｢ペイディ決済らくらく設定」経由のお申し込み限定
            </p>
            <h2 className={styles.title}>
              ペイディ加盟店手数料1か月無料！
              <br />
              お試しキャンペーン実施中
            </h2>
          </div>
          <div>
            <p className={styles.head}>
              無料お試し中ならいつでもキャンセルOK！
            </p>
            <p className={styles.textBottom}>
              ※ペイディとの契約締結日の当月が加盟店手数料無料。 申し込みから
              3日〜1週間程度（目安）で契約締結となります。
            </p>
          </div>
        </Box>

        <Box className={styles.conditions}>
          <Box className={styles.conditionsCol}>
            <Box className={styles.condition}>
              <p className={styles.title}>対象</p>
              <Box className={styles.content}>
                <p className={styles.text}>
                  ｢ペイディ決済らくらく設定」経由でお <br />
                  申込をされた加盟店
                </p>
              </Box>
            </Box>
            <Box className={styles.condition}>
              <p className={styles.title}>無料適用期間</p>
              <Box className={styles.content}>
                <p className={styles.text}>ペイディとの契約成立日当月</p>
              </Box>
            </Box>
            <Box className={styles.condition}>
              <p className={styles.title}>加盟店手数料無料の上限金額</p>
              <Box className={styles.content}>
                <p className={styles.text}>5万円</p>
              </Box>
            </Box>
          </Box>

          <Box className={styles.conditionsCol}>
            <Box className={styles.condition}>
              <p className={styles.title}>ご利用金額とキャンペーン特典</p>
              <Box className={styles.content}>
                <p className={styles.text}>初期費用0円</p>
                <p className={styles.text}>月額費用0円</p>
                <p className={styles.text}>加盟店手数料　物販0%</p>
              </Box>
            </Box>
            <Box className={styles.condition}>
              <p className={styles.title}>キャンペーン終了後</p>
              <Box className={styles.content}>
                <p className={styles.text}>初期費用0円</p>
                <p className={styles.text}>月額費用0円</p>
                <p className={styles.text}>加盟店手数料　物販  3.5%</p>
                <p className={styles.note}>
                  ※6回あと払い、12回あと払いについては別途お申し込が必要となります。
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
